<template>
    <div class="p-6" style="width: 650px">
        <DialogTitle :label="question.index? question.index: 'Question index goes to here '" align="center"/>
        <p class="text-uppercase text-victoria">Used for</p>
        <md-table>
          <md-table-row>
            <md-table-head>Test Id</md-table-head>
            <md-table-head>Title</md-table-head>
            <md-table-head>Type</md-table-head>
          </md-table-row>
          <md-table-row v-for="item in usedFor" :key="item.id">
            <md-table-cell>{{ item.t_id }}</md-table-cell>
            <md-table-cell>{{ item.title }}</md-table-cell>
            <md-table-cell>{{ item.type }}</md-table-cell>
          </md-table-row>
        </md-table>

        <div class="flex justify-center">
            <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
        </div>
    </div>
</template>

<script>
import { DialogTitle } from "@/components";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    DialogTitle
  },
  props: {
      content: {
          type: Object
      }
  },
   computed: {
    ...mapGetters({
      question: "question/getSingleQuestion"
    }),
    usedFor() {
      return this.question.used_for;
    }
  },
  methods: mapMutations({
      dialog: "setShowDialog",
  })
}
</script>