<template>
  <div>
      <Tab :items="items"></Tab>
      <router-view @on-click="onClick" />

      <Dialog class="md-dailog__teacher default-width">
          <components :is="component"></components>
      </Dialog>
  </div>
</template>

<script>
import Tab from '@/components/atom/Tab';
import Dialog from "@/components/atom/Dialog";
import QuestionDetails from "@/components/molecule/question/QuestionDetails";
import QuestionUsedForList from "@/components/molecule/question/QuestionUsedForList";
import CreatedByProfile from "@/components/molecule/user/CreatedByProfile";
import { mapMutations } from "vuex";
export default {
  name: 'TabRouter',
  components: {
      Tab,
      Dialog,
      CreatedByProfile,
      QuestionUsedForList,
      QuestionDetails,
  },
  props: {
    layout: {
      type: String,
    }
  },
  data() {
    return {
      component: 'QuestionDetails',  // Default component
      items: [
        { name: 'Reading', path: this.layout === 'master' ? 'master-pte-question.reading' : 'pte-question.reading' },
        { name: 'Writing', path: this.layout === 'master' ? 'master-pte-question.writing' : 'pte-question.writing' },
        { name: 'Speaking', path: this.layout === 'master' ? 'master-pte-question.speaking' : 'pte-question.speaking' },
        { name: 'Listening', path: this.layout === 'master' ? 'master-pte-question.listening' : 'pte-question.listening' },
      ]
    };
  },
  methods: {
     ...mapMutations({
      dialog: "setShowDialog",
    }),
    onClick({ component }) {
      this.component = component;
      this.dialog(true);
    }
  },
}
</script>