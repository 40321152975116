<template>
  <div class="p-6" style="width: 650px">
    <DialogTitle label="Created By" align="center" />
    <Content class="my-3" label="Name" :text="creatorInfo.name" />
    <Content class="my-3" label="Email Address" :text="creatorInfo.email" />
    <Content class="my-3" label="User Type" :text="creatorInfo.type" />
  </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapGetters } from 'vuex';
export default {
  components: {
    DialogTitle,
    Content,
  },
  computed: {
    ...mapGetters({
      creatorInfo : "user/creatorInfo"
    })
  }
};
</script>